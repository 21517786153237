.loader {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.loader-bubble {
    background: #fcfcff;
    padding: 10px;
    border-radius: 1000px;
    width: 5.2vw;
    height: 5.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.loader-bubble img {
    width: 5vw;
}